.TOS-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: flex-start;
    min-width: 100vw;
    min-height: 100vh;
}

.TOS-top-image {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: -100px;
    justify-items: flex-end;
    justify-content: flex-end;
    z-index: 4;
}
.TOS-top-image img {
    width: 471px;
    height: 251px;
}
.TOS-bottom-image {
    display: inline-flex;
    flex-direction: row;
    justify-items: flex-start;
    justify-content: flex-start;
    margin-top: -20px;
    width: 100%;
    z-index: 4;
}
.TOS-bottom-image img {
    width: 471px;
    height: 251px;
}

.TOS-green-font h5 {
    color: #7EC7B5;
}

.TOS-text {
    width: 800px;
    height: 1877px;
    text-align: left;
}

.TOS-block {
    margin-top: 40px;
}

.TOS-agree p {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    
    color: #5C1E1B;
}

@media only screen and (max-width: 800px) {
    .TOS-top-image img {
        width: 173px;
        height: 92px;
    }
    .TOS-bottom-image img {
        width: 173px;
        height: 92px;
    }
    .TOS-text {
        width: 350px;
        height: 3000px;
    }
}