.HomePage {
    display: flex;
    flex-direction: column;
    
}
.home-top-image {
    display: inline-flex;
    flex-direction: row;
    margin-bottom: -100px;
    z-index: 1;
}
.home-bottom-image {
    display: inline-flex;
    flex-direction: row;
    justify-items: flex-end;
    justify-content: flex-end;
    margin-top: -20px;
    z-index: 1;
}

.Welcome {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 30px;

}

.home-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 452px;
    height: 362px;
}

.home-text p {
    max-width: 408px;
}

.home-text h1 {
    margin: 0px;
}

.home-text h5 {
    margin: 0px;
}
.home-text h6 {
    margin: 0px;
}



.commission-status {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
}

.navigation-buttons-homepage {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    z-index: 1;
}

.hidden-backgrounds-homepage {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    position: relative;
    bottom: 64px;
}

.bg1 {
    margin-left: 1px;
    width: 112px;
    height: 40px;
    background: #eaa1c8;
    border-radius: 50px;
}
.bg2 {
    margin-left: -7px;
    width: 102px;
    height: 40px;
    background: #96CCF3;
    border-radius: 50px;
}
.bg3 {
    margin-left: -7px;
    width: 97px;
    height: 40px;
    background: #7EC7B5;
    border-radius: 50px;
}
.bg4 {
    margin-left: -7px;
    width: 113px;
    height: 40px;
    background: #C29BEA;
    border-radius: 50px;
}

.social-media {
    width: 452px;
    height: 62px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0px;
    margin-top: -65px;
    gap: 20px;
    border-top: 1px dashed #D7CBC1;
}

@media only screen and (min-width: 1920px) {
    
        .Welcome {
            height: 70vh;
        }


    }


@media only screen and (max-width: 800px) {
    .HomePage {
        width: 350px;
        
    }
    .Welcome {
        flex-direction: column;
        width: 100vw;
        align-content: center;
        align-items: center;
    }
    .home-text {
        width: 350px;
        height: auto;
        align-items: center;
        align-content: center;
    }
    .home-text p {
        max-width: 350px;
    }
    .navigation-buttons-homepage {
        flex-direction: column;
    }
    .navigation-buttons-homepage button {
        width: 350px;
        height: auto;
    }
    .commission-status {
        width: 350px;

    }
    .hidden-backgrounds-homepage {
        display: none;
    }
    .social-media {
        width: 350px;
        margin-top: 0;
    }
    .home-top-image img {
        width: 173px;
        margin-bottom: 100px;
    }
    .home-bottom-image img {
        width: 173px;
        right: 0px;
        position: absolute;
    }
    .WelcomeSplash img {
        max-width: 400px;
    }
}