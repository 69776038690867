.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: #FFFBF4;
  width: 100%;
  height: 100%;
}

h1 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #5C1E1B;
}

h2 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: 0.17em;

  /* Brown */

  color: #5C1E1B;
}

h3 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: center;
  letter-spacing: 0.23em;
  text-transform: uppercase;

  /* Brown */

  color: #5C1E1B;
}




h5 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: 0.17em;

  /* Brown */

  color: #5C1E1B;
}
h6 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: 0.17em;
  color: #5C1E1B;
}

p {
  text-align: left;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: 0.03em;
  color: #601C1A;
}

a {
  text-decoration: underline;
  color: #601C1A;
  font-weight: 700;
}

li {
  
    text-align: left;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    /* or 143% */
  
    letter-spacing: 0.03em;
    color: #601C1A;
    margin-left: -17px;
}

button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  background: #F1E4Cf;
  border: 1px solid #5C1E1B;
  border-radius: 50px;
  min-width: 100px;
  max-height: 40px;
  transition: transform 0.2s ease-in, background-color 0.2s ease-in;
}

button:hover {
  cursor: pointer;
  background-color: #FFFFFF;
}

button h5 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.23em;
  text-transform: uppercase;
  color: #5C1E1B;
}

.hidden-backgrounds {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  position: sticky;
  margin-top: -100px;
  height: 100px;
  width: 100%;
  background-color: #FFFBF4;
  top: 0px;
  z-index: 3;
  
}

.bg5-hidden {
  margin-left: -1px;
  width: 100px;
  height: 40px;
  background: #eacd83;
  border-radius: 50px;
}

.bg1-hidden {
  margin-left: -10px;
  width: 112px;
  height: 40px;
  background: #eaa1c8;
  border-radius: 50px;
}
.bg2-hidden {
  margin-left: -7px;
  width: 102px;
  height: 40px;
  background: #96CCF3;
  border-radius: 50px;
}
.bg3-hidden {
  margin-left: -7px;
  width: 97px;
  height: 40px;
  background: #7EC7B5;
  border-radius: 50px;
}
.bg4-hidden {
  margin-left: -7px;
  width: 113px;
  height: 40px;
  background: #C29BEA;
  border-radius: 50px;
}
.nav {
  position: sticky;
  top: 0px;
  height: 100px;
  margin-top: -150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  z-index: 4;
  width: 100%;
  
}
.current-page button {
  background: #5C1E1B;
}

@media only screen and (max-width: 800px) {
  .nav {
    width: 350px;
    flex-direction: row;
    flex-wrap: wrap;
    height: 90px;
    position: static;
    margin-top: 100px;
    align-content: center;
    justify-content: center;
  }
  .nav button {
    width: 50px;
  }
  .hidden-backgrounds {
    display: none;
  }
  
}
html:not([data-scroll='0']) {
  .hidden-backgrounds {
    border-bottom: 1px solid #5C1E1B;
  }
}