.Gallery-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: flex-start;
    min-width: 100vw;
    min-height: 100vh;
}

.gallery-top-image {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: -100px;
    justify-items: flex-end;
    justify-content: flex-end;
    z-index: 4;
}
.gallery-top-image img {
    width: 471px;
    height: 251px;
}
.gallery-bottom-image {
    display: inline-flex;
    flex-direction: row;
    justify-items: flex-start;
    justify-content: flex-start;
    margin-top: -20px;
    width: 100%;
    z-index: 4;
}
.gallery-bottom-image img {
    width: 471px;
    height: 251px;
}

.gallery-frame {
    width: 800px;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.gallery-frame h1 {
    margin-bottom: 15px;
}
.gallery-pink-font h5 {
    color: #EAA1C8;
}


.emotes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 10px;
    
    
}
.emotes2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 10px;
    margin-top: 14px;
    margin-bottom: 40px;
    
    
}

.emote-block {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 390px;
    height: 260px;
    background: #FFFFFF;
    border: 3px solid #5C1E1B;
    box-shadow: 0px 6px 0px #E6D2C3;
    border-radius: 10px;
}

.character-icons {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 800px;
    height: 325px;
    background: #FFFFFF;
    border: 3px solid #5C1E1B;
    box-shadow: 0px 6px 0px #E6D2C3;
    border-radius: 10px;
    margin-bottom: 40px;
}

.character-icons-mobile {
    display: none;
}

.stream-assets {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 800px;
    height: 247.56px;
    background: #FFFFFF;
    border: 3px solid #5C1E1B;
    box-shadow: 0px 6px 0px #E6D2C3;
    border-radius: 10px;
    margin-bottom: 40px;
}

.animations {
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 20px;
    z-index: 2;
}

.character-art {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 800px;
    height: 460px;
    background: #FFFFFF;
    border: 3px solid #5C1E1B;
    box-shadow: 0px 6px 0px #E6D2C3;
    border-radius: 10px;
    margin-bottom: 40px;
}
.character-icons-mobile {
    display: none;
}
.character-art-mobile {
    display: none;
}






@media only screen and (max-width: 800px) {
    .gallery-top-image img {
        width: 173px;
        height: 92px;
    }
    .gallery-bottom-image img {
        width: 173px;
        height: 92px;
    }
    .gallery-frame {
        width: 350px;
        align-content: center;
        align-items: center;
    }
    .emotes {
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .emotes2 {
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .emote-block {
        width: 350px;
        height: auto;
        padding: 10px;
    }
    .emote-block img {
        width: 350px;
    }
    .character-icons {
        display: none;
        margin: 0;
    }
    .character-icons-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 350px;
        border: 3px solid #5C1E1B;
        border-radius: 10px;
        padding: 10px;
    }
    .character-icons-mobile img {
        object-fit: cover;
        width: 350px;
    }
    .stream-assets {
        flex-direction: column;
        width: 350px;
        height: 410px;
        padding: 10px;
    }
    .stream-assets img {
        display: none;
    }
    .animations {
        flex-direction: column;
        gap: 2px;
    }
    video {
        width: 350px;
    }
    .character-art {
        display: none;
    }
    .character-art-mobile {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 350px;
        border: 3px solid #5C1E1B;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 30px;
    }
    .character-art-mobile img {
        max-width: 350px;
        max-height: 200px;
    }

}
