@import url(https://fonts.googleapis.com/css2?family=Fredoka:wght@400;600&family=Quicksand:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: 'Fredoka', 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: #FFFBF4;
  width: 100%;
  height: 100%;
}

h1 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #5C1E1B;
}

h2 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: 0.17em;

  /* Brown */

  color: #5C1E1B;
}

h3 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: center;
  letter-spacing: 0.23em;
  text-transform: uppercase;

  /* Brown */

  color: #5C1E1B;
}




h5 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: 0.17em;

  /* Brown */

  color: #5C1E1B;
}
h6 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: 0.17em;
  color: #5C1E1B;
}

p {
  text-align: left;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: 0.03em;
  color: #601C1A;
}

a {
  text-decoration: underline;
  color: #601C1A;
  font-weight: 700;
}

li {
  
    text-align: left;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    /* or 143% */
  
    letter-spacing: 0.03em;
    color: #601C1A;
    margin-left: -17px;
}

button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  grid-gap: 10px;
  gap: 10px;
  background: #F1E4Cf;
  border: 1px solid #5C1E1B;
  border-radius: 50px;
  min-width: 100px;
  max-height: 40px;
  transition: background-color 0.2s ease-in, -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in, background-color 0.2s ease-in;
  transition: transform 0.2s ease-in, background-color 0.2s ease-in, -webkit-transform 0.2s ease-in;
}

button:hover {
  cursor: pointer;
  background-color: #FFFFFF;
}

button h5 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.23em;
  text-transform: uppercase;
  color: #5C1E1B;
}

.hidden-backgrounds {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  grid-gap: 20px;
  gap: 20px;
  position: -webkit-sticky;
  position: sticky;
  margin-top: -100px;
  height: 100px;
  width: 100%;
  background-color: #FFFBF4;
  top: 0px;
  z-index: 3;
  
}

.bg5-hidden {
  margin-left: -1px;
  width: 100px;
  height: 40px;
  background: #eacd83;
  border-radius: 50px;
}

.bg1-hidden {
  margin-left: -10px;
  width: 112px;
  height: 40px;
  background: #eaa1c8;
  border-radius: 50px;
}
.bg2-hidden {
  margin-left: -7px;
  width: 102px;
  height: 40px;
  background: #96CCF3;
  border-radius: 50px;
}
.bg3-hidden {
  margin-left: -7px;
  width: 97px;
  height: 40px;
  background: #7EC7B5;
  border-radius: 50px;
}
.bg4-hidden {
  margin-left: -7px;
  width: 113px;
  height: 40px;
  background: #C29BEA;
  border-radius: 50px;
}
.nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: 100px;
  margin-top: -150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  grid-gap: 10px;
  gap: 10px;
  z-index: 4;
  width: 100%;
  
}
.current-page button {
  background: #5C1E1B;
}

@media only screen and (max-width: 800px) {
  .nav {
    width: 350px;
    flex-direction: row;
    flex-wrap: wrap;
    height: 90px;
    position: static;
    margin-top: 100px;
    align-content: center;
    justify-content: center;
  }
  .nav button {
    width: 50px;
  }
  .hidden-backgrounds {
    display: none;
  }
  
}
html:not([data-scroll='0']) {
  .hidden-backgrounds {
    border-bottom: 1px solid #5C1E1B;
  }
}
.HomePage {
    display: flex;
    flex-direction: column;
    
}
.home-top-image {
    display: inline-flex;
    flex-direction: row;
    margin-bottom: -100px;
    z-index: 1;
}
.home-bottom-image {
    display: inline-flex;
    flex-direction: row;
    justify-items: flex-end;
    justify-content: flex-end;
    margin-top: -20px;
    z-index: 1;
}

.Welcome {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;

}

.home-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 24px;
    gap: 24px;
    width: 452px;
    height: 362px;
}

.home-text p {
    max-width: 408px;
}

.home-text h1 {
    margin: 0px;
}

.home-text h5 {
    margin: 0px;
}
.home-text h6 {
    margin: 0px;
}



.commission-status {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 10px;
    gap: 10px;
}

.navigation-buttons-homepage {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 10px;
    gap: 10px;
    z-index: 1;
}

.hidden-backgrounds-homepage {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 20px;
    gap: 20px;
    position: relative;
    bottom: 64px;
}

.bg1 {
    margin-left: 1px;
    width: 112px;
    height: 40px;
    background: #eaa1c8;
    border-radius: 50px;
}
.bg2 {
    margin-left: -7px;
    width: 102px;
    height: 40px;
    background: #96CCF3;
    border-radius: 50px;
}
.bg3 {
    margin-left: -7px;
    width: 97px;
    height: 40px;
    background: #7EC7B5;
    border-radius: 50px;
}
.bg4 {
    margin-left: -7px;
    width: 113px;
    height: 40px;
    background: #C29BEA;
    border-radius: 50px;
}

.social-media {
    width: 452px;
    height: 62px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0px;
    margin-top: -65px;
    grid-gap: 20px;
    gap: 20px;
    border-top: 1px dashed #D7CBC1;
}

@media only screen and (min-width: 1920px) {
    
        .Welcome {
            height: 70vh;
        }


    }


@media only screen and (max-width: 800px) {
    .HomePage {
        width: 350px;
        
    }
    .Welcome {
        flex-direction: column;
        width: 100vw;
        align-content: center;
        align-items: center;
    }
    .home-text {
        width: 350px;
        height: auto;
        align-items: center;
        align-content: center;
    }
    .home-text p {
        max-width: 350px;
    }
    .navigation-buttons-homepage {
        flex-direction: column;
    }
    .navigation-buttons-homepage button {
        width: 350px;
        height: auto;
    }
    .commission-status {
        width: 350px;

    }
    .hidden-backgrounds-homepage {
        display: none;
    }
    .social-media {
        width: 350px;
        margin-top: 0;
    }
    .home-top-image img {
        width: 173px;
        margin-bottom: 100px;
    }
    .home-bottom-image img {
        width: 173px;
        right: 0px;
        position: absolute;
    }
    .WelcomeSplash img {
        max-width: 400px;
    }
}
.Gallery-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: flex-start;
    min-width: 100vw;
    min-height: 100vh;
}

.gallery-top-image {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: -100px;
    justify-items: flex-end;
    justify-content: flex-end;
    z-index: 4;
}
.gallery-top-image img {
    width: 471px;
    height: 251px;
}
.gallery-bottom-image {
    display: inline-flex;
    flex-direction: row;
    justify-items: flex-start;
    justify-content: flex-start;
    margin-top: -20px;
    width: 100%;
    z-index: 4;
}
.gallery-bottom-image img {
    width: 471px;
    height: 251px;
}

.gallery-frame {
    width: 800px;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.gallery-frame h1 {
    margin-bottom: 15px;
}
.gallery-pink-font h5 {
    color: #EAA1C8;
}


.emotes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    grid-gap: 10px;
    gap: 10px;
    
    
}
.emotes2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 14px;
    margin-bottom: 40px;
    
    
}

.emote-block {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 390px;
    height: 260px;
    background: #FFFFFF;
    border: 3px solid #5C1E1B;
    box-shadow: 0px 6px 0px #E6D2C3;
    border-radius: 10px;
}

.character-icons {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 800px;
    height: 325px;
    background: #FFFFFF;
    border: 3px solid #5C1E1B;
    box-shadow: 0px 6px 0px #E6D2C3;
    border-radius: 10px;
    margin-bottom: 40px;
}

.character-icons-mobile {
    display: none;
}

.stream-assets {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 800px;
    height: 247.56px;
    background: #FFFFFF;
    border: 3px solid #5C1E1B;
    box-shadow: 0px 6px 0px #E6D2C3;
    border-radius: 10px;
    margin-bottom: 40px;
}

.animations {
    position: absolute;
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    z-index: 2;
}

.character-art {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 800px;
    height: 460px;
    background: #FFFFFF;
    border: 3px solid #5C1E1B;
    box-shadow: 0px 6px 0px #E6D2C3;
    border-radius: 10px;
    margin-bottom: 40px;
}
.character-icons-mobile {
    display: none;
}
.character-art-mobile {
    display: none;
}






@media only screen and (max-width: 800px) {
    .gallery-top-image img {
        width: 173px;
        height: 92px;
    }
    .gallery-bottom-image img {
        width: 173px;
        height: 92px;
    }
    .gallery-frame {
        width: 350px;
        align-content: center;
        align-items: center;
    }
    .emotes {
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .emotes2 {
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .emote-block {
        width: 350px;
        height: auto;
        padding: 10px;
    }
    .emote-block img {
        width: 350px;
    }
    .character-icons {
        display: none;
        margin: 0;
    }
    .character-icons-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 350px;
        border: 3px solid #5C1E1B;
        border-radius: 10px;
        padding: 10px;
    }
    .character-icons-mobile img {
        object-fit: cover;
        width: 350px;
    }
    .stream-assets {
        flex-direction: column;
        width: 350px;
        height: 410px;
        padding: 10px;
    }
    .stream-assets img {
        display: none;
    }
    .animations {
        flex-direction: column;
        grid-gap: 2px;
        gap: 2px;
    }
    video {
        width: 350px;
    }
    .character-art {
        display: none;
    }
    .character-art-mobile {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 350px;
        border: 3px solid #5C1E1B;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 30px;
    }
    .character-art-mobile img {
        max-width: 350px;
        max-height: 200px;
    }

}

.Pricing-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    min-width: 100vw;
    min-height: 100vh;
    height: 2689px;
}

.pricing-top-image {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: -100px;
    z-index: 4;
    
    
}
.pricing-top-image img {
    width: 471px;
    height: 251px;
}
.pricing-bottom-image {
    position: absolute;
    top: 2438px;
    display: inline-flex;
    flex-direction: row;
    justify-items: flex-end;
    justify-content: flex-end;
    z-index: 4;
    width: 100%;
}
.pricing-bottom-image img {
    width: 471px;
    height: 251px;
}

.pricing-blue-font h5 {
    color: #96CCF3;
}

.pricing-frame {
    width: 800px;
    text-align: left;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 146px;
}
.pricing-frame h1 {
    margin-bottom: 5px;
}

.pricing-frame p {
    margin-bottom: 20px;
}
.pricing-frame h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
    font-weight: 400;
}

.pricing-item {
    width: 800px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: #FFFFFF;
    border: 3px solid #5C1E1B;
    border-radius: 10px;
    padding: 10px 0px;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 30px;
}
.pricing-breakdown {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
    width: 382px;

}
.pricing-category-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: space-between;
    align-content: center;
    align-items: center;
    width: 382px;
    height: 28px;
    position: relative;
    top: 24px;
}

.price-actual {
    display: flex;
    align-items: center;
    align-content: center;
    border: 1px solid #5C1E1B;
    border-radius: 40px;
    height: 28px;
    padding: 4px 13px;
}
.price-actual h5 {
    font-family: 'Quicksand';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.03em;
    margin: 0px;
}

.extra-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-content: flex-end;
    width: 100%;
    justify-content: center;
    justify-items: center;
    margin-top: 50px;
    grid-gap: 10px;
    gap: 10px;
}

.extra-buttons button {
    background: #5C1E1B;
    border: 1px solid #5C1E1B;
    border-radius: 50px;
}

.extra-buttons h5 {
    color: #FFFFFF;
    font-family: 'Fredoka';
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
}
.extra-buttons h5:hover {
    color: #EAA1C8;
    cursor: pointer;
}

.animated-emotes {
    position: absolute;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    left: 40px;
    top: 470px;
    z-index: 2;
}
.animated-emotes img {
    width: 155px;
    height: 155px;
}

.animated-charicons {
    position: absolute;
    left: 35px;
    top: 740px;
}
.animated-charicons img {
    width: 331px;
    height: 184px;
    
}

.emotes-high {
    width: 368px;
    height: 240px;
    position: relative;
    display: none;
}

.emotes-high img {
    width: 368px;
    height: 240px;
    
}

.pngtuber {
    display: flex;
    width: 348px;
    height: 273px;
    position: relative;
    right: 380px;
    top: 8px;
    margin-right: -348px;
}

.vtuber {
    display: flex;
    width: 348px;
    height: 273px;
    position: relative;
    right: 380px;
    top: 8px;
    margin-right: -348px;
}

@media only screen and (max-width: 800px) {
    .Pricing-main {
        height: 4000px;
    }
    .pricing-top-image img {
        width: 173px;
        height: 92px;
    }
    .pricing-bottom-image img {
        width: 173px;
        height: 92px;
    }
    .pricing-bottom-image{
        top: 3910px;
    }
    .pricing-frame {
        width: 350px;
        position: static;
        align-items: center;
    }
    .pricing-item {
        width: 350px;
        flex-direction: column;
        padding: 10px;
    }
    .pricing-item img {
        max-width: 350px;
        
    }
    .pricing-breakdown {
        width: 350px;
    }
    .pricing-category-text {
        width: 350px;
    }
    .animated-emotes {
        position: static;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .animated-charicons {
        position: static;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .mobile-anim {
        margin-bottom: -215px;
    }
    .price-actual h5 {
        font-size: 13px;
    }
    .pngtuber {
        display: flex;
        width: 348px;
        height: 273px;
        position: relative;
        right: 0px;
        bottom: 273px;
        top: auto;
        margin-bottom: -273px;
    }
    
    .vtuber {
        display: flex;
        width: 348px;
        height: 273px;
        position: relative;
        right: auto;
        top: auto;
        bottom: 273px;
        margin-bottom: -273px;
    }
}
.Request-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: flex-start;
    min-width: 100vw;
    min-height: 100vh;
}

.request-top-image {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: -100px;
    z-index: 4;
    
}
.request-top-image img {
    width: 471px;
    height: 251px;
}
.request-bottom-image {
    display: inline-flex;
    flex-direction: row;
    justify-items: flex-end;
    justify-content: flex-end;
    margin-top: -20px;
    width: 100%;
    z-index: 4;
}
.request-bottom-image img {
    width: 471px;
    height: 251px;
}
.request-purple-font h5 {
    color: #C29BEA;
}

.content-contact {
    width: 800px;
    text-align: left;
    display: flex;
    flex-direction: column;

}
.content-contact h1 {
    margin-bottom: 10px;
}

.content-contact h2 {
    font-family: 'Fredoka';
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.17em;
    margin-bottom: 10px;
}

label {
    font-family: 'Fredoka';
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.23em;
    color: #5C1E1B;
    margin-left: 15px;
}


input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    
    grid-gap: 10px;
    
    gap: 10px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #5C1E1B;
    border-radius: 20px;
    font-family: 'Quicksand';
    font-size: 14px;
    color: #5C1E1B;
    padding: 0px 15px;
}

.getintouch2 {
    width: 800px;
}

.textfield {
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
    margin-bottom: 20px;
    width: 800px;
}

.textfield input {
    width: 800px;
}

textarea {
    display: flex;
    width: 800px;
    flex-direction: row;
    align-items: flex-start;
    height: 221px;
    background: #FFFFFF;
    border: 1px solid #5C1E1B;
    border-radius: 20px;
    font-family: 'Quicksand';
    font-size: 14px;
    color: #5C1E1B;
    padding: 10px 15px;
}

.message {
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
}
.submit {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 25px;

}

.submit button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 158px;
    height: 40px;
    background: #5C1E1B;
    border: 1px solid #5C1E1B;
    border-radius: 50px;
    flex-wrap: nowrap;
    padding: 0px;
}

.submit button:disabled {
    background: #7E7675;
    cursor: auto;
    
}
.submit h5 {
    color: #FFFFFF;
    font-family: 'Fredoka';
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin: 0px;
    letter-spacing: 0.23em;
}

/*.file-upload {
    margin-bottom: 20px;
    min-width: 800px;
}

.file-upload input {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding: 0px 15px;
    width: 623px;
}

.real-file {
    position: relative;
    top: 42px;
    left: 649px;
    z-index: 2;
    opacity: 0;
    margin-top: -40px;
}

.real-file input {
    width: 120px;
    
}


.real-file input:hover {
    cursor: pointer;
}

.dummy-file {
    display: flex;
    gap: 16px;
    align-items: center;
    align-content: center;
    min-width: 800px;
}

.dummy-file input {
    min-width: 623px;
}

.dummy-file button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 158px;
    height: 40px;
    background: #5C1E1B;
    border: 1px solid #5C1E1B;
    border-radius: 50px;
    flex-wrap: nowrap;
    padding: 0px;
}

.dummy-file h5 {
    color: #FFFFFF;
    font-family: 'Fredoka';
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin: 0px;
    letter-spacing: 0.23em;
} */

.services {
    width: 800px;
    height: auto;
    background-color: #FFFFFF;
    border: 3px solid #5C1E1B;
    border-radius: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
}

.services button {
    font-family: 'Fredoka';
    
}

.service-options {
    display: flex;
    flex-direction: row;
    margin-top: -15px;
    
}

.styled-option input {
    position: absolute;
    cursor: pointer;
    width: 100%;
    max-width: 200px;
    opacity: 0;
    
}



.styled-option {
    display: inline-flex;
    align-content: center;
    align-items: center;
}

.checkmark {
    height: 17px;
    width: 17px;
    background: #FFFBF4;
    border-radius: 50px;
}
.styled-option input:checked ~ button {
    background: #5C1E1B;
}

.styled-option input:checked ~ button h5 {
    color: #7EC7B5;
}
.styled-option input:checked ~ button .checkmark {
    background-color: #5C1E1B;
    width: 5px;
    height: 10px;
    border: solid #7EC7B5;
    border-width: 0 3px 3px 0;
    border-radius: 0px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: relative;
    bottom: 2px;
    margin-right: 8px;
}

@media only screen and (max-width: 800px) {
    .Request-main {
        overflow-x: hidden;
    }
    .request-top-image img {
        width: 173px;
        height: 92px;
    }
    .request-bottom-image img {
        width: 173px;
        height: 92px;
    }
    .content-contact {
        width: 350px;
        align-items: center;
        align-content: center;
    }
    .textfield {
        width: 320px;
    }
    .textfield input {
        width: 320px;
    }
    textarea {
        width: 320px;
    }
    .services {
        width: 310px;
        align-items: center;
        padding-left: 15px;
        padding-bottom: 30px;
        padding-right: 20px;
        
    }
    .service-options {
        flex-wrap: wrap;
        grid-gap: 6px;
        gap: 6px;
        align-content: center;
        align-items: center;
    }
    .submit {
        width: 350px;
    }
    .getintouch2 {
        width: 350px;
        align-content: center;
        align-items: center;
        
    }
    .styled-option input {
        max-width: 280px;
    }
    .styled-option button {
        max-width: 280px;
        min-width: 150px;
        display: flex;
        justify-content: flex-start;

    }
    .services button h5 {
        font-size: 12px;
    }
}



.TOS-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: flex-start;
    min-width: 100vw;
    min-height: 100vh;
}

.TOS-top-image {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: -100px;
    justify-items: flex-end;
    justify-content: flex-end;
    z-index: 4;
}
.TOS-top-image img {
    width: 471px;
    height: 251px;
}
.TOS-bottom-image {
    display: inline-flex;
    flex-direction: row;
    justify-items: flex-start;
    justify-content: flex-start;
    margin-top: -20px;
    width: 100%;
    z-index: 4;
}
.TOS-bottom-image img {
    width: 471px;
    height: 251px;
}

.TOS-green-font h5 {
    color: #7EC7B5;
}

.TOS-text {
    width: 800px;
    height: 1877px;
    text-align: left;
}

.TOS-block {
    margin-top: 40px;
}

.TOS-agree p {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    
    color: #5C1E1B;
}

@media only screen and (max-width: 800px) {
    .TOS-top-image img {
        width: 173px;
        height: 92px;
    }
    .TOS-bottom-image img {
        width: 173px;
        height: 92px;
    }
    .TOS-text {
        width: 350px;
        height: 3000px;
    }
}
